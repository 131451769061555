import {
  Box,
  ExpandableSection,
  SpaceBetween,
  TextContent
} from "@amzn/awsui-components-react";
import ReactJson from "react-json-view";
import { useContext } from "react";
import { AccountingEventsGroup } from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";

interface Props {
  eventData: AccountingEventsGroup;
}

export default function EventResults(props: Props) {
  const { eventData } = props;
  const { accountingEvents } = eventData;
  const darkMode = useContext(DarkModeContext);
  return (
    <SpaceBetween size="l">
      {accountingEvents ? (
        Object.keys(accountingEvents).map((key) => {
          return (
            <SpaceBetween size="xl" key={key}>
              <ExpandableSection
                header={
                  <Box variant="h4" key={key} margin={{ top: "xxs" }}>
                    {key}
                    <br/>
                    {accountingEvents[key].creationDate &&
                      <TextContent>
                        <small>Created on: {new Date(accountingEvents[key].creationDate).toISOString().replace("T", " ")}</small>
                      </TextContent>
                    }
                  </Box>
                }
                variant="container"
              >
                <ReactJson
                  src={accountingEvents[key]}
                  displayDataTypes={false}
                  sortKeys
                  theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
                />
              </ExpandableSection>
            </SpaceBetween>
          );
        })
      ) : (
        <ReactJson src={eventData} displayDataTypes={false} />
      )}
    </SpaceBetween>
  );
}
