import { Container, Grid, Header } from "@amzn/awsui-components-react";
import { useContext } from "react";
import EventsVersionSelector from "./EventsVersionSelector";
import CbeResults from "./CbeResults";
import ErrorAlert from "./ErrorAlert";
import useFetchCbe from "../hooks/useFetchCbe";
import useFetchEvent from "../hooks/useFetchEvent";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import CbeVersionSelector from "./CbeVersionSelector";

export default function Results() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const { data: eventData } = useFetchEvent(stage, orderId);
  const { data: cbeData } = useFetchCbe(stage, orderId);

  const isEventNotEmpty = eventData !== undefined && eventData.length !== 0;

  const isCbeNotEmpty = cbeData !== undefined && cbeData.length !== 0;

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 6 } },
      ]}
    >
      <Container header={<Header variant="h2"> Events </Header>}>
        {isEventNotEmpty ? (
          <EventsVersionSelector eventData={eventData} />
        ) : (
          <ErrorAlert header="Could not find any events" content="" />
        )}
      </Container>
      <Container header={<Header variant="h2"> CBEs </Header>}>
        {isCbeNotEmpty ? (
          <CbeVersionSelector cbeData={cbeData}/>
        ) : (
          <ErrorAlert header="Could not find any CBEs" content="" />
        )}
      </Container>
    </Grid>
  );
}
