import { ColumnLayout, Container, Header } from "@amzn/awsui-components-react";
import { useContext } from "react";
import useFetchEvent from "../../hooks/useFetchEvent";
import ExternalLinks from "./ExternalLinks";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";

export default function OrderIdBar() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const { data: eventData } = useFetchEvent(stage, orderId);
  return (
    <Container>
      <ColumnLayout columns={2}>
        <Header variant="h1" description="Order ID">
          {orderId}{" "}
        </Header>
        {eventData !== undefined && eventData[0] !== undefined && (
          <ExternalLinks eventData={eventData[0]} />
        )}
      </ColumnLayout>
    </Container>
  );
}
